import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MailchimpService } from '../../Service/mailchimp.service';

@Component({
  selector: 'app-enroll',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.css']
})
export class EnrollComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
