import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class SecureHttp {
  constructor(private _http: HttpClient) { }

  headers: HttpHeaders = this.getRequestOptions();

  get(url) {

    const headers = this.getRequestOptions();
    return this._http.get<any>(url, { headers })
      .pipe(catchError(this.handleError));
  }

  put(url, data) {
    const headers = this.getRequestOptions();
    return this._http.post(url, data, { headers })
      .pipe(catchError(this.handleError));
  }

  post(url, data) {
    const headers = this.getRequestOptions();
    const params = new HttpParams()
				.set('FNAME', data.firstName)
        .set('LNAME', data.lastName)
        .set('EMAIL', data.email);
    url = url + '&' + params.toString();
    console.log(url);
    return this._http.jsonp<any>(url, 'c').pipe(catchError(this.handleError));
  }

  postSendEmail(url, data) {
    const headers = new HttpHeaders({
      'content-type': 'multipart/form-data',
      'Origin': document.location.protocol + '//' + document.location.hostname
    });
    return this._http.post(url, { headers })
    .pipe(catchError(this.handleError));
  }

  delete(url) {
    const headers = this.getRequestOptions();
    return this._http.delete(url, { headers })
      .pipe(catchError(this.handleError));
  }


  private getRequestOptions(): HttpHeaders {
    const options = new HttpHeaders({
                'Authorization': 'Basic ' + btoa('wsca:5b4903eb97a089845902be9fd01e0977-us19') ,
                'content-type': 'application/json',
                'Origin': document.location.protocol + '//' + document.location.hostname
              });
    return options;
  }

  private handleError = (err: Response) => {
    if (err.status === 401 && err['error']['error'] === 'Unauthorized') {

    } else {
      return Observable.throw(err);
    }
  }

}

