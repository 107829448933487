import { Component, OnInit, ChangeDetectionStrategy, ViewChild, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EmailFormModel } from '../../Model/email-form.model';
import { MailchimpService } from '../../Service/mailchimp.service';
import { ContactComponent } from '../contact/contact/contact.component';
import { MailingListComponent } from '../mailing-list/mailing-list/mailing-list.component';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent implements OnInit {
  title = 'app';
  isMobileMode = false;
  isMiddleMode = false;
  isBetweenMode = false
  success: boolean;
  @ViewChild('form')form: NgForm;
  year = new Date().getFullYear();
  images = true;
  emailForm: EmailFormModel = new EmailFormModel();
  imageObject = [
    {

    }
  ]

  @HostListener('window:resize') onResize() {
    if (window.innerWidth <= 500) {
      this.isMobileMode = true;
    } else {
      this.isMobileMode = false;
    }

    if (window.innerWidth <= 1195 && window.innerWidth > 500) {
      this.isMiddleMode = true;
    } else {
      this.isMiddleMode = false;
    }
  }

  constructor(
    private toast: ToastrService,
    private _modal: NgbModal,
    private mailChimpService: MailchimpService
  ) {
    this.onResize();
  }

  ngOnInit() {

      ;

  }

  goToFacebook() {
    const url = 'https://www.facebook.com/wschristianacademy';
    window.open(url, '_blank');
  }

  // onSubmit() {
  //   this.mailChimpService.addSubscriber(this.emailForm).subscribe( (res) => {
  //     console.log(res);
  //     this.headerForm.reset();
  //     this.footerForm.reset();
  //     if (res.result === 'success') {
  //       this.toast.show('Thank you for joining the list!', 'Success', {
  //         toastClass: 'success-toast-class',
  //         closeButton: false,
  //         progressBar: false,
  //         positionClass: 'toast-bottom-right',
  //         timeOut: 5000,
  //         extendedTimeOut: 1000,
  //       });
  //     } else {
  //       let message = res.msg;
  //       if (message.indexOf('already subscribed') !== -1) {
  //         message = res.msg.substring(0, res.msg.indexOf('<'));
  //       }

  //       this.toast.show(message, 'Error', {
  //         toastClass: 'error-toast-class',
  //         closeButton: false,
  //         progressBar: false,
  //         positionClass: 'toast-bottom-right',
  //         timeOut: 5000,
  //         extendedTimeOut: 1000,
  //       });
  //     }
  //   },
  //   (error) => {
  //     this.headerForm.reset();
  //     this.footerForm.reset();
  //     this.toast.show('An error has occurred. Refresh and try again.', 'Error', {
  //       toastClass: 'error-toast-class',
  //       closeButton: false,
  //       progressBar: false,
  //       positionClass: 'toast-bottom-right',
  //       timeOut: 5000,
  //     });

  //   }
  // );
  // }



  openContactForm() {
    const modalRef = this._modal.open(ContactComponent, {backdrop: 'static', keyboard: false, size: 'xl'});
    modalRef.result.then((result) => {
        if (result) {
          console.log(result);
        }
      });
  }

  onSubmit() {
    this.mailChimpService.addSubscriber(this.emailForm).subscribe( (res) => {
      console.log(res);
      this.form.reset();
      if (res.result === 'success') {
        this.toast.show('Thank you for joining the list!', 'Success', {
          toastClass: 'success-toast-class',
          closeButton: false,
          progressBar: false,
          positionClass: 'toast-bottom-right',
          timeOut: 5000,
          extendedTimeOut: 1000,
        });
      } else {
        let message = res.msg;
        if (message.indexOf('already subscribed') !== -1) {
          message = res.msg.substring(0, res.msg.indexOf('<'));
        }

        this.toast.show(message, 'Error', {
          toastClass: 'error-toast-class',
          closeButton: false,
          progressBar: false,
          positionClass: 'toast-bottom-right',
          timeOut: 5000,
          extendedTimeOut: 1000,
        });
      }
    },
    (error) => {
      this.form.reset();
      this.toast.show('An error has occurred. Refresh and try again.', 'Error', {
        toastClass: 'error-toast-class',
        closeButton: false,
        progressBar: false,
        positionClass: 'toast-bottom-right',
        timeOut: 5000,
      });

    }
  );
  }

}
