import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EmailFormModel } from '../../../Model/email-form.model';
import { MailchimpService } from '../../../Service/mailchimp.service';

@Component({
  selector: 'app-mailing-list',
  templateUrl: './mailing-list.component.html',
  styleUrls: ['./mailing-list.component.css']
})
export class MailingListComponent implements OnInit {


  emailForm: EmailFormModel = new EmailFormModel();
  @ViewChild('form')form: NgForm;
  constructor(
    private toast: ToastrService,
    private mailChimpService: MailchimpService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.mailChimpService.addSubscriber(this.emailForm).subscribe( (res) => {
      console.log(res);
      this.form.reset();
      if (res.result === 'success') {
        this.activeModal.close();
        this.toast.show('Thank you for joining the list!', 'Success', {
          toastClass: 'success-toast-class',
          closeButton: false,
          progressBar: false,
          positionClass: 'toast-bottom-right',
          timeOut: 5000,
          extendedTimeOut: 1000,
        });
      } else {
        let message = res.msg;
        if (message.indexOf('already subscribed') !== -1) {
          message = res.msg.substring(0, res.msg.indexOf('<'));
        }

        this.toast.show(message, 'Error', {
          toastClass: 'error-toast-class',
          closeButton: false,
          progressBar: false,
          positionClass: 'toast-bottom-right',
          timeOut: 5000,
          extendedTimeOut: 1000,
        });
      }
    },
    (error) => {
      this.form.reset();
      this.toast.show('An error has occurred. Refresh and try again.', 'Error', {
        toastClass: 'error-toast-class',
        closeButton: false,
        progressBar: false,
        positionClass: 'toast-bottom-right',
        timeOut: 5000,
      });

    }
  );
  }

}
