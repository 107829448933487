import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MailchimpService } from '../../Service/mailchimp.service';

@Component({
  selector: 'app-faith-statement',
  templateUrl: './faith-statement.component.html',
  styleUrls: ['./faith-statement.component.css']
})
export class FaithStatementComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
