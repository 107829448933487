import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MailchimpService } from '../../Service/mailchimp.service';
import { ContactComponent } from '../contact/contact/contact.component';
import { MailingListComponent } from '../mailing-list/mailing-list/mailing-list.component';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit {

  tab: string;
  showNav: boolean = false;

  constructor(
    private router: Router,
    private toast: ToastrService,
    private _modal: NgbModal,
    private mailChimpService: MailchimpService
  ) { }

  ngOnInit(): void {
    this.tab = sessionStorage.getItem('activeTab');

    setTimeout(() => {

      this.opemMailingList();

    }, 3000)
  }

  goToPage(page: string) {
    sessionStorage.setItem('activeTab', page);
    this.router.navigateByUrl(page);
  }

  opemMailingList() {
    if(sessionStorage.getItem('mailingListShow') === 'no') return;

    sessionStorage.setItem('mailingListShow', 'no');
    const modalRef = this._modal.open(MailingListComponent, {backdrop: 'static', keyboard: false, size: 'lg'});
    modalRef.result.then((result) => {
        if (result) {}
      });
  }

  openContactForm() {
    const modalRef = this._modal.open(ContactComponent, {backdrop: 'static', keyboard: false, size: 'xl'});
    modalRef.result.then((result) => {
        if (result) {
          console.log(result);
        }
      });
  }

  goToBottom() {
    if(this.showNav)this.showNav = false;
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
  }
}
