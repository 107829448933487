import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  setHours,
  setMinutes,
  parse,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarEventTitleFormatter,
  CalendarMonthViewDay,
  CalendarView,
} from 'angular-calendar';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { CustomEventTitleFormatter } from './custom-event-title-formatter/custom-event-title-formatter.provider';

const colors: any = {
  red: {
    primary: '#007bff',
    secondary: '#002159',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ]
})
export class CalendarComponent implements OnInit {

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date('2023-08-15T18:00:00');

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [];

  refresh = new Subject<void>();

  showBadge: boolean = false;

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;
  daysInWeek = 7;
  private destroy$ = new Subject<void>();

  constructor(
    private modal: NgbModal,
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef) {}

  ngOnInit() {

    this.events  = [

      {
        start: new Date('2023-08-15T18:00:00'),
        end: new Date('2023-08-15T20:00:00'),
        title: 'Open House - Back to School Night',
        color: colors.red,
        actions: this.actions,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-08-17T09:00:00'),
        end: new Date('2023-08-17T15:00:00'),
        title: 'First Day of School',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-09-04T09:00:00'),
        end: new Date('2023-09-04T15:00:00'),
        title: 'Labor Day (No School)',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-09-18T09:00:00'),
        end: new Date('2023-09-21T15:00:00'),
        title: 'School Pictures',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-10-11T09:00:00'),
        end: new Date('2023-10-11T15:00:00'),
        title: 'Dental Screening',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-10-13T09:00:00'),
        end: new Date('2023-10-13T15:00:00'),
        title: 'End of Q1',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-10-16T09:00:00'),
        end: new Date('2023-10-20T15:00:00'),
        title: 'Fall Break',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-11-03T09:00:00'),
        end: new Date('2023-11-03T15:00:00'),
        title: 'Q1 Report Cards go home',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-11-07T09:00:00'),
        end: new Date('2023-11-07T15:00:00'),
        title: 'Election Day (No School)',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-11-10T09:00:00'),
        end: new Date('2023-11-10T15:00:00'),
        title: 'Veteran\'s Day (No School)',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-11-22T09:00:00'),
        end: new Date('2023-11-24T15:00:00'),
        title: 'Thanksgiving Break (No School)',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-12-12T09:00:00'),
        end: new Date('2023-12-12T15:00:00'),
        title: 'Student Christmas Performance',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-12-15T09:00:00'),
        end: new Date('2023-12-15T15:00:00'),
        title: 'Christmas Celebration',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-12-15T09:00:00'),
        end: new Date('2023-12-15T15:00:00'),
        title: 'End of Q2',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2023-12-18T09:00:00'),
        end: new Date('2023-12-31T15:00:00'),
        title: 'Christmas Break (No School)',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-01-01T09:00:00'),
        end: new Date('2024-01-03T15:00:00'),
        title: 'Christmas Break (No School)',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-01-04T09:00:00'),
        end: new Date('2024-01-04T15:00:00'),
        title: 'School Resumes',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-01-12T09:00:00'),
        end: new Date('2024-01-12T15:00:00'),
        title: 'Q2 Report Cards go home',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-01-16T09:00:00'),
        end: new Date('2024-01-16T15:00:00'),
        title: 'MLK Jr. Day (No School)',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-02-20T09:00:00'),
        end: new Date('2024-02-20T15:00:00'),
        title: 'President\'s Day (No School)',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-03-01T17:00:00'),
        end: new Date('2024-03-01T20:00:00'),
        title: 'WSCA Art Show',
        color: colors.red,
        actions: this.actions,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-03-08T09:00:00'),
        end: new Date('2024-03-08T15:00:00'),
        title: 'End of Q3',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-03-25T09:00:00'),
        end: new Date('2024-03-29T15:00:00'),
        title: 'Spring Break',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-04-20T17:00:00'),
        end: new Date('2024-04-20T20:00:00'),
        title: 'Night of Encouragement',
        color: colors.red,
        actions: this.actions,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-05-17T17:00:00'),
        end: new Date('2024-05-17T20:00:00'),
        title: 'End of Year Celebration',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-05-24T17:00:00'),
        end: new Date('2024-05-24T20:00:00'),
        title: 'Last Day of School',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },
      {
        start: new Date('2024-05-24T17:00:00'),
        end: new Date('2024-05-24T20:00:00'),
        title: 'End of Q4',
        color: colors.red,
        actions: this.actions,
        allDay: true,
        meta: {
          incrementsBadgeTotal: this.showBadge,
        },
      },

    ]

    const CALENDAR_RESPONSIVE = {
      small: {
        breakpoint: '(max-width: 576px)',
        daysInWeek: 2,
      },
      medium: {
        breakpoint: '(max-width: 768px)',
        daysInWeek: 3,
      },
      large: {
        breakpoint: '(max-width: 960px)',
        daysInWeek: 5,
      },
    };

    this.breakpointObserver
      .observe(
        Object.values(CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint)
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE).find(
          ({ breakpoint }) => !!state.breakpoints[breakpoint]
        );
        if (foundBreakpoint) {
          this.daysInWeek = foundBreakpoint.daysInWeek;
        } else {
          this.daysInWeek = 7;
        }
        this.cd.markForCheck();
      });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


  ngOnDestroy() {
    this.destroy$.next();
  }

  getDate(year: number, month: number, day: number, hours: number, minutes: number) {

    let date = new Date();
    date.setFullYear(year);
    date.setMonth(month);
    date.setDate(day);
    date.setHours(hours);
    date.setMinutes(minutes);
    console.log(date);
    return date;
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((day) => {
      day.badgeTotal = day.events.filter(
        (event) => event.meta.incrementsBadgeTotal
      ).length;
    });
  }
}
