import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmailFormModel } from '../../../Model/email-form.model';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MailchimpService } from '../../../Service/mailchimp.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  emailForm: EmailFormModel = new EmailFormModel();
  @ViewChild('form')form: NgForm;
  public editor = DecoupledEditor;
  config = {
    uiColor: '#99000',
    toolbar: {
      items: [
        "fontFamily",
        "fontSize",
        'fontColor',
        'fontBackgroundColor',
        "|",
        'bold',
        "italic",
        "underline",
        "strikethrough",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "insertTable",
        "indent",
        "outdent",
        "|",
        "blockQuote",
        "undo",
        "redo"
      ]
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    language: 'en'
  }

  constructor(
    public activeModal: NgbActiveModal,
    private mailChimpService: MailchimpService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  onSubmit() {

    let html = {
      "email": this.emailForm.email,
      "name": this.emailForm.firstName + ' ' + this.emailForm.lastName,
      "message": this.emailForm.message,
      "_subject": "Visitor Generated Email from website",
    }

    this.mailChimpService.sendEmail(html).subscribe( (res) => {
      console.log(res);
      this.form.reset();

    });
  }

}
