import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { EmailFormModel } from '../Model/email-form.model';
import { SecureHttp } from './secure-http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { Subject } from 'rxjs';

@Injectable()
export class MailchimpService {

  activeTab: Subject<string> = new Subject<string>();

  constructor(private _secureHttp: SecureHttp) { }

  public addSubscriber(data: EmailFormModel): Observable<any> {
    const url = `https://westsideca.us19.list-manage.com/subscribe/post-json?u=abb3898cb551813c243f539b1&id=e29f97081e`;
    return this._secureHttp.post(url, data)
    .pipe(map((res: any) => res),
      catchError((err, caught) => {
        return of(err);
      }));
  }

  public sendEmail(data: any): Observable<any> {
    const url = `https://mailthis.to/info@westsideca.net`;

    return this._secureHttp.postSendEmail(url, data)
    .pipe(map((res: any) => res),
      catchError((err, caught) => {
        return of(err);
      }));
  }

}
