import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MailchimpService } from './../Service/mailchimp.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { SecureHttp } from '../Service/secure-http';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgImageSliderModule } from 'ng-image-slider';
import { MailingListComponent } from './mailing-list/mailing-list/mailing-list.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { NgbCarousel, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactComponent } from './contact/contact/contact.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TopNavComponent } from './top-nav/top-nav.component';
import { FaithStatementComponent } from './faith-statement/faith-statement.component';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { HomePageComponent } from './home-page/home-page.component';
import { FooterComponent } from './footer/footer.component';
import { EnrollComponent } from './enroll/enroll.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './calendar/calendar.component';

@NgModule({
  declarations: [
    AppComponent,
    MailingListComponent,
    ContactComponent,
    TopNavComponent,
    FaithStatementComponent,
    HomePageComponent,
    FooterComponent,
    EnrollComponent,
    CalendarComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgImageSliderModule,
    IvyCarouselModule,
    NgbModule,
    CKEditorModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    })

  ],
  providers: [
    MailchimpService,
    SecureHttp,
    ToastrService
  ],
  bootstrap: [AppComponent],
  exports: [TopNavComponent, FooterComponent]
})
export class AppModule { }
