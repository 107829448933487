
import { Route } from "@angular/router";
import { CalendarComponent } from "./calendar/calendar.component";
import { EnrollComponent } from "./enroll/enroll.component";
import { FaithStatementComponent } from "./faith-statement/faith-statement.component";
import { HomePageComponent } from "./home-page/home-page.component";

export const routes: Route[] = [

    {
      path: '',
      component: HomePageComponent
    },
    {
      path: 'about',
      component: FaithStatementComponent
    },
    {
      path: 'enroll',
      component: EnrollComponent
    },
    {
      path: 'calendar',
      component: CalendarComponent
    },
    { path: '**', component: HomePageComponent }
];
